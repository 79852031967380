@import '~@sravni/design-system-theme/lib/scss/core/variables';

.titleWrapper {
  padding-top: 16px;

  @media screen and (min-width: $breakpoint-desktop) {
    padding-top: 24px;
  }
}

.title {
  padding-bottom: 16px;

  @media screen and (min-width: $breakpoint-desktop) {
    padding-bottom: 24px;
  }
}

.barContainer {
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.bar {
  display: flex;
  flex-grow: 1;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 0.5fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-grow: 1;

  grid-template-areas:
  "osago credit loans"
  "osago credit deposits"
  "vzr mortgageInsurance mortgageInsurance"
  "creditsRating creditsRating sportInsurance"
  "mortgage kasko serviceMenu";

  @media screen and (min-width: $breakpoint-tablet) {
    height: 206px;
    padding-right: 30px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1.5fr);
    grid-template-areas:
    "osago credit mortgageInsurance sportInsurance vzr kasko serviceMenu"
    "osago credit loans creditsRating deposits mortgage serviceMenu";
  }

  @media screen and (min-width: $breakpoint-desktop) {
    margin-left: 0;
    padding-left: 0;

    &.withBanner {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas:
    "osago credit mortgageInsurance sportInsurance vzr kasko serviceMenu"
    "osago credit loans creditsRating deposits mortgage serviceMenu";
    }
  }

  @media screen and (min-width: '1150px') {
    margin: auto;
    padding-right: 0;
  }
}
