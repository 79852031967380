@import '~@sravni/design-system-theme/lib/scss/core/variables';

.container {
  display: grid;

  gap: 24px;
  grid-template-columns: repeat(1, 1fr);
  // grid-template-rows: repeat(3, 1fr);
  grid-template-areas: 
    "deposits"
    "mortgage"
    "mortgageInsurance";

  @media screen and (min-width: $breakpoint-tablet) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 
      "deposits deposits deposits deposits"
      "mortgage mortgage mortgage mortgageInsurance";
  }

  @media screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-template-areas: "deposits deposits deposits mortgage mortgage mortgage mortgageInsurance";
  }
}
