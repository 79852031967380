@import '~@sravni/design-system-theme/lib/scss/core/variables';

.propositionCard {
  padding-top: 0;
  padding-bottom: 0;
}

.propositionsWrapper {
  max-height: inherit;
  max-width: 100%;
  flex-wrap: nowrap;

  @media screen and (min-width: $breakpoint-tablet) {
    max-height: 100px;
    max-width: calc(50% - 16px);
    flex-wrap: wrap;
  }
}

.propositionLink {
  color: var(--color-D100);
  width: 100%;
}

.propositionPeriod {
  width: 155px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.propositionRateWrapper {
  width: 85px;
}

.propositionRate {
  border-radius: 8px;
  padding: 4px 8px;
  background-color: var(--color-G06);
}

.arrowIcon {
  color: var(--color-D20);
}

.divider {
  width: calc(100% + 24px);
  margin-left: -16px;
}

.propositionsLink {
  max-width: 100%;

  @media screen and (min-width: $breakpoint-tablet) {
    max-width: calc(50% - 16px);
  }
}

.rateSkeleton {
  max-width: 70px;
}