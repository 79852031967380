@import '~@sravni/design-system-theme/lib/scss/core/variables';

.container {
  padding-top: 9px;
  padding-bottom: 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  row-gap: 4px;

  @media screen and (min-width: $breakpoint-desktop) {
    margin-left: 0;
    margin-right: 0;
    padding-top: 19px;
    padding-bottom: 19px;
  }
}

.currencyItem {
  margin-right: 20px;

  @media screen and (min-width: $breakpoint-desktop) {
    display: flex;
    align-items: center;
    margin-right: 40px;

    white-space: nowrap;
  }

  &__diff {
    margin-left: 8px;

    &--up {
      color: var(--color-G100);
    }

    &--down {
      color: var(--color-R100);
    }
  }

  &__course {
    color: var(--color-D100);
    font-weight: bold;
  }
}
