@import '~@sravni/design-system-theme/lib/scss/core/variables';


.wrapper {
  width: 150px;
  margin-right: 8px;
  cursor: pointer;

  &.isMobile {
    width: 100%;
    padding-bottom: 12px;
    margin-right: 16px;

    @media screen and (min-width: $breakpoint-tablet) {
      margin-right: 30px;
    }
  }
}
