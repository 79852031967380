@import '~@sravni/design-system-theme/lib/scss/core/variables';
.card {
  overflow: hidden;
  position: relative;
  padding-top: 9px;
  padding-bottom: 9px;

  @media screen and (min-width: $breakpoint-desktop) {
    margin-left: 0;
    margin-right: 0;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.wrapper {
  column-gap: 12px;
  row-gap: 4px;

  @media screen and (min-width: $breakpoint-desktop) {
    column-gap: 16px;
  }
}

.titleWrapper, .linksWrapper {
  width: auto;
}

.link {
  text-wrap: nowrap;
}
