@import '~@sravni/design-system-theme/lib/scss/core/variables';

.link {
    flex-grow: 1;
    grid-area: mortgageInsurance;
}

.card {
    position: relative;

    min-height: 140px;

    @media screen and (min-width: $breakpoint-tablet) {
        height: 100%;
        min-width: 236px;
    }

    @media screen and (min-width: $breakpoint-desktop) {
        height: auto;
        min-width: 180px;
        min-height: 208px;   
    }
}

.badge {
    position: absolute;
    left: 16px;
    top: -9px;
}

.img {
    position: absolute;
    right: 10px;
    bottom: 54px;

    height: 75px;
}